import { Button } from "antd";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getApiUrl } from "../../utils";
import moment from "moment-business-days";

class Countdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            aMorningDepartureTime: undefined, // Orario mattiniero
            aEveningDepartureTime: undefined, // Orario serale
            countdown: undefined, // Countdown visualizzato
        };
        this.updateLocale = this.updateLocale.bind(this);
        this.getTimeCountdown = this.getTimeCountdown.bind(this);
        this.calculateNextTargetTime = this.calculateNextTargetTime.bind(this);
        this.updateCountdown = this.updateCountdown.bind(this);
    }

    componentDidMount() {
        // Chiama la funzione per ottenere i dati
        this.updateLocale();
        this.getTimeCountdown();
        // Imposta l'aggiornamento del countdown ogni secondo
        this.intervalId = setInterval(this.updateCountdown, 1000);
    }

    componentWillUnmount() {
        // Pulisce l'intervallo quando il componente si smonta
        clearInterval(this.intervalId);
    }

    render() {
        const { countdown } = this.state;

        return (
            countdown !== undefined &&
            <div style={{
                textAlign: "center", lineHeight: "20px"
            }}>
                <h1 style={{ color: "white" }}>Completa l'ordine entro:</h1>
                <div
                    style={{
                        color: "#f37c2e",
                        fontSize: "2em",
                        fontWeight: "bold"
                    }}
                >
                    {countdown}
                </div>
                <h1 style={{ color: "white" }}>per avere la spedizione più veloce</h1>
            </div>
        );
    }

    updateLocale() {
        const currentYear = moment().year();
        const easterMondays = [
            "2025-04-21",
            "2026-04-06",
            "2027-03-29",
            "2028-04-17",
            "2029-04-02",
            "2030-04-22",
        ];

        moment.updateLocale("it", {
            workingWeekdays: [1, 2, 3, 4, 5], // Lun-Ven
            holidays: [
                `${currentYear}-01-01`, // 1° gennaio
                `${currentYear}-01-06`, // 6 gennaio
                `${currentYear}-04-25`, // 25 aprile
                `${currentYear}-05-01`, // 1 maggio
                `${currentYear}-06-02`, // 2 giugno
                `${currentYear}-08-15`, // 15 agosto
                `${currentYear}-11-01`, // 1 novembre
                `${currentYear}-12-08`, // 8 dicembre
                `${currentYear}-12-25`, // 25 dicembre
                `${currentYear}-12-26`, // 26 dicembre
                ...easterMondays
            ],
            holidayFormat: "YYYY-MM-DD", // Formato delle date delle festività
        });


    }

    async getTimeCountdown() {
        console.log("chiamata al countdown api")
        fetch(getApiUrl() + "/documents/getAverageDeparture", {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept": 'application/json'
            }),
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res);
                } else {
                    let resData = res.data
                    if (resData) {
                        this.setState({
                            ...this.state,
                            aMorningDepartureTime: resData.aMorningDepartureTime,
                            aEveningDepartureTime: resData.aEveningDepartureTime,
                            //aMorningDepartureTime: "09:40:00",
                            //aEveningDepartureTime: "19:12:00",

                        })
                    }

                }
            })
            .catch(res => {
                console.log("Errore nel recuperare i dati" + res)
            })
    }

    calculateNextTargetTime() {
        const { aMorningDepartureTime, aEveningDepartureTime } = this.state;

        // Controlla se gli orari sono validi
        if (!aMorningDepartureTime && !aEveningDepartureTime) {
            return null; // Nessun orario valido
        }

        // Ottieni l'orario attuale
        const now = moment();

        // Parsing degli orari in moment.js
        const todayMorning = aMorningDepartureTime
            ? moment(`${now.format('YYYY-MM-DD')} ${aMorningDepartureTime}`, 'YYYY-MM-DD HH:mm:ss.sss')
            : null;

        const todayEvening = aEveningDepartureTime
            ? moment(`${now.format('YYYY-MM-DD')} ${aEveningDepartureTime}`, 'YYYY-MM-DD HH:mm:ss.sss')
            : null;

        // Lista di orari validi
        const validTimes = [todayMorning, todayEvening].filter(time => time && time.isAfter(now));

        // Se non ci sono orari validi per oggi, considera il prossimo giorno lavorativo
        if (validTimes.length === 0) {
            const nextBusinessDay = moment().businessAdd(1); // Prossimo giorno lavorativo
            const nextMorning = aMorningDepartureTime
                ? moment(`${nextBusinessDay.format('YYYY-MM-DD')} ${aMorningDepartureTime}`, 'YYYY-MM-DD HH:mm:ss.sss')
                : null;

            const nextEvening = aEveningDepartureTime
                ? moment(`${nextBusinessDay.format('YYYY-MM-DD')} ${aEveningDepartureTime}`, 'YYYY-MM-DD HH:mm:ss.sss')
                : null;

            return nextMorning || nextEvening; // Restituisci il prossimo orario valido
        }

        // Restituisci il tempo più vicino tra quelli validi
        return validTimes.reduce((nearest, time) => (time.isBefore(nearest) ? time : nearest));

    }


    updateCountdown() {
        const targetTime = this.calculateNextTargetTime();
        if (!targetTime) return;
        const now = new Date();
        
        
        const timeDiff = targetTime - now; // Differenza in millisecondi
        if (timeDiff > 0) {
            const totalSeconds = Math.floor(timeDiff / 1000);
            const hours = Math.floor((totalSeconds) / 3600); // Ore residue
            const minutes = Math.floor((totalSeconds % 3600) / 60); // Minuti residui
            let hString = hours > 0 ? hours.toString() + "h : " : ""
            let mString = (hString === "" && minutes == 0) ? "< 1m" : minutes.toString() + "m"
            //const seconds = totalSeconds % 60; // Secondi residui
            //let sString = " : "+seconds.toString()+"s" 
            //let sString = seconds.toString()+"s"
            this.setState({
                countdown: `${hString}${mString}`,
            });
        }
    }

}


export default Countdown