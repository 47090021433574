import React from 'react';
import { Breadcrumb, Col, Row, message } from 'antd';
import {Layout as AntdLayout} from 'antd';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import TopHeader from '../TopHeader/topheader.js'
import MenuBar from '../MenuBar/menubar.js'
import MessageBar from '../MessageBar/messagebar.js'

import Home from '../Home/home.js'
import Cart from '../Cart/cart.js'
import OrderList from '../OrderList/orderList.js'
import Logout from '../Logout/logout.js'
import Missing from '../MissingArticles/missing.js'
import Documents from '../Documents/documents.js'
import PromoConsole from '../PromoConsole/promoconsole.js'
import PromoList from '../PromoList/promoList.js'
import PromoDetail from '../PromoDetail/promoDetail.js'
import PromoEditor from '../PromoEditor/promoEditor.js'
import Init from '../Init/init.js'
import Contacts from '../Contacts/contacts.js'
import './layout.css';
import logout from '../Logout/logout.js';
import {setLoginMessage} from '../../actions/actions.js';
import { connect } from "react-redux";
import Kstore from '../KStore/kstore.js';
import KStoreLanding from '../KStore/kstoreLanding.js';
import LoadFile from '../LoadFile/loadFile.js';
import LoadTariffario from '../LoadFile/loadTariffario.js';
import ResiTemp from '../Resi/resiTemp.js'
import Resi from '../Resi/resi.js'
import ResiReq from '../Resi/richiesteReso.js'
import NuovoReso from '../Resi/nuovoReso.js'
import Behalf from '../Behalf/behalf.js'
import Registration from '../Registration/registration.js';
import AgentReport from '../AgentReport/agentReport.js';
import {getApiUrl} from '../../utils.js'
import StockCheck from '../StockCheck/stockCheck.js';
import SearchReport from '../SearchReport/searchReport.js';
import CustomerInfoContainer from '../CustomerInfo/customerInfoContainer.js';
import Obsolete from '../Obsolete/obsolete.js';
import ListaObsolete from '../Obsolete/listaObsolete.js';
import ScontiObsolete from '../Obsolete/scontiObsolete.js';
import VisualizzaSconti from '../Obsolete/visualizzaSconti.js';
import Preventivo from '../Preventivo/preventivo.js'
import OrderListTracking from '../OrderList/orderListTracking.js';
import GSStore from '../GSStore/gsStore.js';
const { Header, Content, Footer } = AntdLayout;

const mapStateToProps = state => {
    return { 
        init : state.init,
        user: state.user,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setLoginMessage : (message) => dispatch(setLoginMessage(message))
    };
}


class Layout extends React.Component {

    constructor(props) {
        super(props);

        /*
         * LOCAL STATE
        */
        this.state = {
            init: this.props.init,
            //user: this.props.user
          
        }
       this.checkCensimento = this.checkCensimento.bind(this)
    }

    componentDidMount() {
        this.timer = setInterval(()=> this.checkSession(), 20000);
        //this.checkCensimento()
    }

    componentWillUnmount() {
        clearInterval(this.timer)
        this.timer = null; 
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        
        if (this.props.init.apiVersion !== prevProps.init.apiVersion) {
          this.setState ({
            init: this.props.init,
            //user: this.props.user
          });
        }
    }

    checkSession() {
        
        fetch(getApiUrl() + '/auth/check?key=' + Math.random().toString(36).slice(2), {
            credentials: "include",
            headers: new Headers({
                "Content-Type": 'application/json',
                "Accept" : 'application/json'
            })
        })
        .then(response => {
            if(response.ok){
                return response.json();
            }
            return Promise.reject(response)
        })
        .catch(e => {
            if(e.status === 401){
                this.props.setLoginMessage("La sessione è scaduta. Si prega di effettuare nuovamente l'accesso.");
                let t = this
                setTimeout(function(){ t.props.history.push("/login") }, 3000);                
            }
        })
    }

    render() {
        return (
            <AntdLayout className="layout">

                <Header className="header1">
                   <Content className="site-layout layout"> 
                        <MessageBar />
                   </Content>
                </Header>
                <Header className="header2">
                   <Content className="site-layout layout"> 
                      <TopHeader />
                      <MenuBar />
                   </Content>
                </Header>

                <Init />
                
                <Content className="site-layout site-layout-content">

                    {
                    /* BREADCUM */
                    }
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>Sirmec B2B</Breadcrumb.Item>
                        <Switch>
                            <Route exact path="/home">
                                <Breadcrumb.Item>Ricerca Codici</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/missing">
                                <Breadcrumb.Item>Lista mancanti</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/cart">
                                <Breadcrumb.Item>Carrello</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/orderList">
                                <Breadcrumb.Item>Storico Ordini</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/documents">
                                <Breadcrumb.Item>Documenti</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/promoConsole">
                                <Breadcrumb.Item>Promo Console</Breadcrumb.Item>
                            </Route>
                            <Route path="/promoEditor">
                                <Breadcrumb.Item>Promo Editor</Breadcrumb.Item>
                            </Route>
                            
                            <Route exact path="/logout">
                                <Logout />
                            </Route>
                            <Route exact path="/promoList">
                                <Breadcrumb.Item>Promo</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/promoDetail">
                                <Breadcrumb.Item>Dettagli Promo</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/contacts">
                                <Breadcrumb.Item>Contatti</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/loadFile">
                                <Breadcrumb.Item>Carica un file CSV</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/orderListTracking">
                                <Breadcrumb.Item>Lista ordini</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/loadTariffario">
                                <Breadcrumb.Item>Carica un file CSV</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/kstoreLanding">
                                <Breadcrumb.Item>KStore - Aggiungi al carrello</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/obsolete">
                                <Breadcrumb.Item>Carica Obsoleti</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/ListaObsolete">
                                <Breadcrumb.Item>Mostra Obsoleti</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/ScontiObsolete">
                                <Breadcrumb.Item>Carica Obsoleti</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/VisualizzaSconti">
                                <Breadcrumb.Item>Visualizza Sconti</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/Preventivo">
                                <Breadcrumb.Item>Richiedi Preventivo</Breadcrumb.Item>
                            </Route>
                            {/* RESI */}
                            <Route exact path="/listaResi">
                                <Breadcrumb.Item>Lista Resi</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/nuovoReso">
                                <Breadcrumb.Item>Nuovo Reso</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/richiesteReso">
                                <Breadcrumb.Item>Richieste di Reso</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/behalf">
                                <Breadcrumb.Item>Impersonifica un utente</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/stockCheck">
                                <Breadcrumb.Item>Analisi dello stock</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/searchReport">
                                <Breadcrumb.Item>Report delle ricerche</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/registration">
                                <Breadcrumb.Item>Gestione registrazione utente</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/customerInfo">
                                <Breadcrumb.Item>Profilo cliente</Breadcrumb.Item>
                            </Route>
                            <Route exact path="/gsStore">
                            <Breadcrumb.Item>Gomma Service</Breadcrumb.Item>
                            </Route>
                        </Switch>
                    </Breadcrumb>

                    <Switch>
                        <Route exact path="/home">
                            <Home />
                        </Route>
                        <Route exact path="/missing">
                            <Missing />
                        </Route>
                        <Route exact path = "/registration">
                            <Registration />
                        </Route>
                        <Route exact path = "/customerInfo">
                            <CustomerInfoContainer/>
                        </Route>
                        <Route exact path="/cart">
                            <Cart />
                        </Route>
                        <Route exact path="/orderList">
                            <OrderList />
                        </Route>
                        <Route exact path="/documents">
                            <Documents />
                        </Route>
                        <Route exact path="/promoConsole">
                            <PromoConsole />
                        </Route>
                        <Route exact path="/logout">
                            <Logout />
                        </Route>
                        <Route exact path="/promoList">
                            <PromoList />
                        </Route>
                        <Route path="/promoDetail">
                            <PromoDetail />
                        </Route>
                        <Route path="/promoEditor">
                            <PromoEditor />
                            </Route>
                        <Route path="/contacts">
                            <Contacts />
                        </Route>
                        <Route path="/kstore">
                            <Kstore />
                        </Route>
                        <Route path="/loadFile">
                            <LoadFile />
                        </Route>
                        <Route path="/loadTariffario">
                            <LoadTariffario />
                        </Route>
                        <Route path="/orderListTracking">
                            <OrderListTracking />
                        </Route>
                        <Route path="/obsolete">
                            <Obsolete />
                        </Route>
                         <Route path="/preventivo">
                            <Preventivo></Preventivo>
                        </Route> 
                        <Route path="/listaObsolete">
                            <ListaObsolete />
                        </Route>
                        <Route path="/scontiObsolete">
                            <ScontiObsolete />
                        </Route>
                        <Route path="/VisualizzaSconti">
                            <VisualizzaSconti />
                        </Route>
                        <Route path="/kstoreLanding">
                            <KStoreLanding />        
                        </Route>
                        <Route path="/listaResi">
                            <Resi />        
                        </Route>
                        <Route path="/nuovoReso">
                            <NuovoReso /> 
                        </Route>
                        <Route path="/richiesteReso">
                            <ResiReq />        
                        </Route>
                        <Route path="/behalf">
                            <Behalf />        
                        </Route>
                        <Route path="/reportConsole/000000">
                            <AgentReport />        
                        </Route>
                        <Route path="/stockCheck">
                            <StockCheck />        
                        </Route>
                        <Route path="/searchReport">
                            <SearchReport />        
                        </Route>
                        <Route path="/gsStore">
                            <GSStore/>
                        </Route>
                    </Switch>
                </Content>

                <Footer style={{ textAlign: 'center' }}>Sirmec S.r.l. - CLIENT VERSION: {process.env.REACT_APP_VERSION} - API VERSION: - {this.state.init.apiVersion} </Footer>
            </AntdLayout>
        )
    }

    checkCensimento() {

        fetch(getApiUrl() + '/registration/checkCensimento', {
            credentials: 'include',
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }),
        }).then(
            res => res.json()
            ).then(
                (res) => {
            if (res.error) {
                throw(res.error)
            } else {
                const primaVolta = localStorage.getItem("primaVolta")
                
            if((res.data === 'MISSING' || res.data === 'TO_UPDATE') && this.props.user.uRole !== 'ADMIN' && JSON.parse(primaVolta) != 'true' /*&& this.props.user.isBehelf != true && this.props.history.location.pathname === '/home'*/){
               
                message.info("Profilo clienti non aggiornato. Prego provvedere" )
                localStorage.setItem("primaVolta", JSON.stringify("true"));
                this.props.history.push('/customerInfo')
            }
             
        }
        })

    }


}
export default connect(mapStateToProps,mapDispatchToProps)(Layout);
