import React from 'react';
import {withRouter} from 'react-router-dom'
import { Upload, message } from 'antd';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { Radio, Table, Button, Alert, Typography } from 'antd';
import DataColumnUtil from '../SearchResults/dataColumnUtil.js'
import Icon from '@ant-design/icons';
import {getApiUrl} from '../../utils.js'
import {fetchCart} from "../../actions/actions.js"
import { connect } from "react-redux";

function mapDispatchToProps(dispatch) {
  return {
      fetchCart : () => dispatch(fetchCart()),
  };
}
class LoadFile extends React.Component {

    constructor(props) {
        super(props);


        let initialState = {
          mode : "CSV",
          ris : {
            startInst : null,
            endInst: null,
            running : false,
            resultCarts : null,
          }
        }

        /*
         * LOCAL STATE
        */
        this.state = {
          mode: "CSV",
          links: {
            CSV: "https://www.dropbox.com/scl/fi/k9g2lrtgwosqwbbjhpf8p/csvVirgola.csv?rlkey=ii090ist61meaxv3k9d8vp2f9&st=o51yrd3z&dl=1",
            SEMICOLON: "https://www.dropbox.com/scl/fi/bh7fog52zi2e5axhszzv9/csvPuntoVirgola.csv?rlkey=216s0v57yjp1gfs8o5cnsifp9&st=s8r3w6lc&dl=1",
            PIPE: "https://www.dropbox.com/scl/fi/d7qw8sgvyut5hm5e1ru0c/eper.csv?rlkey=vba18ewpc2p3c0o72zc7pcejb&st=aludzgya&dl=1"
          },
          ris : {
            startInst : null,
            endInst: null,
            running : false,
            resultCarts : [],
          }
        }

        this.onChangeMode = this.onChangeMode.bind(this);
        this.setResults = this.setResults.bind(this);
        this.initLoad = this.initLoad.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.handleDownload = this.handleDownload.bind(this); 

    }

    componentDidMount() {
      this.getStatus();

      this.interval = setInterval(() => {
        this.getStatus();
      }, 10000);
    }

    componentWillUnmount(){
      clearInterval(this.interval);
    }

    getStatus(){

      let fetchCartLocal = this.props.fetchCart;

      fetch(getApiUrl() + "/cart/addFromCSVStatus/" ,{
        method: 'GET', 
        cache: 'no-cache',
        credentials: 'include',
        headers: new Headers({
            "Accept" : 'application/json'
        }),
      })
      .then(res => res.json())
      .then(res => {
      if(res.error) {
        throw(res);
      }
      else{
        
        if(res.data!==undefined && res.data !== null){
          this.setState({
            ...this.state,
            ris : res.data
          }, ()=> { console.log("State after getStatus..."); console.log(this.state) }  )
        }

        //Update Carts
        if(res.data!==undefined && res.data.running!==undefined && res.data.running===false){
           fetchCartLocal()
        }

      }
    })
    .catch(res => {
      message.error("Errore nel recuperare lo stato degli upload" + res) 
    })


    }

    onChangeMode(ev){
      this.setState({
        ...this.state,
        mode : ev.target.value
      })
    }

    initLoad(){

      /*
      this.setState({
        ...this.state,
        ris : null,
      })
      */

    }

    setResults(ris){

      if(ris===undefined || ris===null){
        return
      }

      this.setState({
        ...this.state,
        ris : ris
      }, ()=> { console.log("State after setResults..."); console.log(this.state) } )
    }

    showCarts() {
      this.props.history.push('/cart')
    }

    handleDownload() {
      const { mode, links } = this.state;
      const link = document.createElement('a');
      link.href = links[mode];
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    
    render() {

        const { Dragger } = Upload;
        let mode = this.state.mode

        let state = this.state;
        let setResults = this.setResults;
        let initLoad = this.initLoad;

        const props = {
            name: 'file',
            multiple: false,
            customRequest: function(obj) {
              
              initLoad();

              console.log(obj);

              var formData  = new FormData();
              //formData.append("file", obj.data);
              formData.append("file", obj.file);

              fetch(getApiUrl() + "/cart/addFromCSV" + "?mode=" + mode, {
                    method: 'POST', 
                    cache: 'no-cache',
                    credentials: 'include',
                    headers: new Headers({
                        "Accept" : 'application/json'
                    }),
                    body : formData
              })
              .then(res => res.json())
              .then(res => {
                setResults(res);
                obj.onSuccess(res)
              })
              .catch(err => {
                obj.onError(err)
              });
            },
            
            onChange: function(info) {
              const { status } = info.file;
              if (status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
              } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            },
          };


         let config = {
            onChangeQntRich : null,
            onAddToChart : null,
            onFindCrossReference : null,
            onDeliveryDate : null,
            onNoteClick : null
         };
        
        let dtc = new DataColumnUtil();
        let columns = dtc.createProductTableCol(config);  
        const ImgCartDetail = () => (
          <img style={{width:"2.2em",marginTop:"-5px"}} src="icon/icons8-carrello-della-spesa-48-tratto-4.png"></img>
        );
        const IconCartDetail = props => <Icon component={ImgCartDetail} {...props} />;
        
        const { Title } = Typography;

        return (
            <div>
                
            <div style={{ textAlign: 'center' }}>
              <h1 style={{ fontSize: '20px' }}>Formato del file</h1>
              <Radio.Group onChange={this.onChangeMode} value={this.state.mode}>
                <Radio style={{ fontSize: '16px' }} value={"CSV"}>CSV con separatore virgola</Radio>
                <Radio style={{ fontSize: '16px' }} value={"SEMICOLON"}>CSV con separatore punto e virgola</Radio>
                <Radio style={{ fontSize: '16px' }} value={"PIPE"}>CSV scaricato da EPER</Radio>
                {/*<Radio value={"TXT_FROM_OF"}>Export Infinity Ordine Fornitore</Radio>*/}
              </Radio.Group>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center', // Per centrare anche verticalmente (se necessario)
                  width: '100%',
                }}
              >
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  size="large"
                  onClick={this.handleDownload}
                  style={{
                    fontSize: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '16px',
                    marginBottom: '16px', // Spaziatura tra il bottone e il dragger
                  }}
                >
                  Scarica qui il tuo file di esempio
                </Button>
              </div>

              <Dragger
                {...props}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <h1 style={{ fontSize: '16px' }}>Seleziona o trascina un file che contiene i precodici, i codici e le quantità da caricare nel carrello</h1>
                <h1 style={{ fontSize: '16px' }}>Il file deve contenere per ogni riga il precodice, il codice e la quantità</h1>
                <h1 style={{ fontSize: '16px' }}>Per il file scaricato da EPER non occorre fare nulla</h1>
              </Dragger>

            </div>

                {/* LOADING IN PROGRESS */}
                { this.state.ris.startInst !==undefined && this.state.ris.startInst !== null && this.state.ris.running === true && (
                  <div>
                  <Title level={4}> E' ancora in corso il carimento del file iniziato il {this.state.ris.startInst}. Al completato del caricamento del file, potrai visualizzare qui sotto un riepilogo dei prodotti caricati. </Title>
                  </div>
                ) 
                }

                {/* LAST LOADINF */}
                { (this.state.ris.running === false && this.state.ris.resultCarts || []).map( c =>  
                    (
                    <div>
                    <br />
                    <Title level={4}> Ultimo caricamento completato il {this.state.ris.endInst}. Segue riepilogo dei prodotti caricati: </Title>
                     <p>Carrello {c.cId} </p>
                     <Table columns={columns} dataSource={c.productsList} />
                  </div>
                  )                
                )}

            </div>
        )
    }

}

export default withRouter(connect(null,mapDispatchToProps)(LoadFile));