import React from 'react';
import { getApiUrl } from '../../utils';
import { message } from 'antd';


class GSStore extends React.Component {


    constructor(props) {
        super(props);
        this.openGSStore = this.openGSStore.bind(this);
    }


    openGSStore(){

        let height = window.screen.height * 0.85
        let width  = window.screen.width *0.85

        console.log("Open Gomma Service")
        fetch(getApiUrl() + "/gs/getLoginUrl", {
            credentials: "include",
            headers: new Headers({
                "Accept" : 'application/json'
            })
        })
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            window.open(res.data, '_blank', 'location=yes,height=' + height + ',width=' + width )
        })
        .catch(error => {
            message.error("Errore nell'apertura dello store Gomma Service");
        })
    }

    componentDidMount(){
        this.openGSStore();
    }

    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    shouldComponentUpdate(){
    }

    render() {
        return null;
    }
}
export default GSStore;